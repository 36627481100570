import {Point, Rectangle, Size} from "../helpers/Rectangle";

export interface UpdateArgs {
    canvasSize: Size;
    singleMargin: number;
    comboMargin: number;
    gridSize: number;
    distanceA: number;
    distanceB: number;
}

export interface ConnectorDrawingProps {
    drawDecorations: boolean;
    drawShapes: boolean;
    drawRulers: boolean;
    drawSpots: boolean;
    drawPaths: boolean;
    drawLines: boolean;
    comboMargin: number;
    singleMargin: number;
}

export interface IDiagram{
    update(args: UpdateArgs): void;
    shapes: DiagramShape[];
    hotspots: DiagramHotspot[];
    paths: DiagramPath[];
    draggingShapes? : DiagramShape[];
    verticalRulers?: number[];
    horizontalRulers?: number[];
    decorations?: DiagramDecoration[];
    lines?: DiagramLine[];
    sensors?: DiagramSensor[];
    gizmos?: DiagramGizmo[];
}

export interface MouseEventData {
    point: Point;
}

export type MouseEvent = (e: MouseEventData) => any;

export interface DiagramSensorEvents {
    mouseDown?: MouseEvent;
    mouseMove?: MouseEvent;
    mouseUp?: MouseEvent;
    mouseEnter?: MouseEvent;
    mouseLeave?: MouseEvent;
    cursor?: string | null;
}

export type DiagramSensorEvent = {
    [key in keyof DiagramSensorEvents]?: DiagramSensorEvents[key];
}

export class DiagramShape {
    hot: boolean = false;
    constructor(
        public bounds: Rectangle,
    ){}
}

export class DiagramHotspot{
    constructor(readonly point: Point){}
}

export class DiagramDecoration{
    constructor(
        public bounds: Rectangle,
        public fillStyle: string | null = null,
        public strokeStyle: string | null = null){}
}

export class DiagramGizmo{
    hot: boolean = false;
    constructor(
        public bounds: Rectangle,
    ){}
}

export class DiagramLine {
    constructor(public a: Point, public b: Point){}
}

export class DiagramPath{
    constructor(readonly points: Point[]){}
}

export class DiagramSensor {
    hot: boolean = false;
    constructor(public bounds: Rectangle, readonly events: DiagramSensorEvents){}
}