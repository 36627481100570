// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../icons/0822-cross2.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../icons/0175-floppy-disk.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../icons/0180-file-empty.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../icons/0187-folder.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../icons/0826-chevron-up.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../icons/0827-chevron-down.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../icons/0828-chevron-left.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../icons/0829-chevron-right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "body{font:caption}.ui-panel>div{margin:50px}.ui-icon.icon-cross{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.ui-icon.icon-floppy{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.ui-icon.icon-file{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.ui-icon.icon-folder{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.ui-icon.icon-chevron-up{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.ui-icon.icon-chevron-down{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.ui-icon.icon-chevron-left{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.ui-icon.icon-chevron-right{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}\n", ""]);
// Exports
module.exports = exports;
