import {BasicCardinalPoint, Rectangle} from "../helpers/Rectangle";
import {
    DiagramDecoration,
    DiagramGizmo,
    DiagramHotspot,
    DiagramLine,
    DiagramPath, DiagramSensor,
    DiagramShape,
    UpdateArgs
} from "./IDiagram";
import {OrthogonalConnector} from "./OrthogonalConnector";

type Side = 'top' | 'right' | 'bottom' | 'left';

interface ConnectorPoint {
    shape: DiagramShape;
    side: Side;
    distance: number;
}

function sideToCardinal(s: Side): BasicCardinalPoint{
    switch (s) {
        case "top": return 'n';
        case "right": return 'e';
        case "bottom": return 's';
        case "left": return 'w';
    }
}

export class DiagramGrid{

    shapes: DiagramShape[] = [
        new DiagramShape(new Rectangle(150, 300, 100, 100)),
        // new DiagramShape(new Rectangle(300, 400, 100, 100)),
        new DiagramShape(new Rectangle(500, 500, 100, 150)),
    ];
    hotspots: DiagramHotspot[] = [];
    paths: DiagramPath[] = [];
    draggingShapes? : DiagramShape[] = [];
    verticalRulers?: number[];
    horizontalRulers?: number[];
    decorations?: DiagramDecoration[];
    lines?: DiagramLine[];
    sensors?: DiagramSensor[] = [];
    extraDecor: DiagramDecoration[] = [];
    connectorPointA: ConnectorPoint;
    connectorPointB: ConnectorPoint;
    gizmos?: DiagramGizmo[] = [];

    private updateHandlers: ((args: UpdateArgs) => void)[] = [];

    constructor(){
        const a = this.shapes[0];
        const b = this.shapes[1];

        this.connectorPointA = {
            shape: a,
            side: 'right',
            distance: 0.5,
        };

        this.connectorPointB = {
            shape: b,
            side: 'left',
            distance: 0.5
        };

        this.shapeSensors(this.connectorPointA);
        this.shapeSensors(this.connectorPointB);
    }

    private shapeSensors(p: ConnectorPoint){

        const shape = p.shape;
        const spotSize = 20;
        const sensorArea = 100;
        const spots: DiagramGizmo[] = [];
        const sensors: DiagramSensor[] = [];

        const createSensor = (side: Side) => {
            const spot = new DiagramGizmo(Rectangle.empty);
            const sensor = new DiagramSensor(Rectangle.empty, {
                mouseEnter: () => spot.hot = true,
                mouseLeave: () => spot.hot = false,
                mouseDown: () => p.side = side,
                cursor: `${sideToCardinal(side)}-resize`
            });
            spots.push(spot);
            sensors.push(sensor);
            this.updateHandlers.push(() => {
                spot.bounds = Rectangle.fromPoint(
                    shape.bounds.getCardinalPoint( sideToCardinal(side) ))
                    .inflate(spotSize, spotSize);
                sensor.bounds = spot.bounds;
            });
        };

        const spotsVisible = (visible: boolean) => {

            if(!this.gizmos) {
                this.gizmos = [];
            }

            this.gizmos = this.gizmos.filter(d => spots.indexOf(d) < 0);

            if(visible) {
                this.gizmos = [...this.gizmos, ...spots];
            }
        };

        const boundsSensor = new DiagramSensor(Rectangle.empty, {
            mouseEnter: () => spotsVisible(true),
            mouseLeave: () => spotsVisible(false),
        });
        const shapeSensor = new DiagramSensor(Rectangle.empty, {
            mouseEnter: () => shape.hot = true,
            mouseLeave: () => shape.hot = false,
            cursor: 'move',
        });

        createSensor('top');
        createSensor('right');
        createSensor('bottom');
        createSensor('left');

        // Update sensor bounds when update happens
        this.updateHandlers.push( () => {
            shapeSensor.bounds = shape.bounds;
            boundsSensor.bounds = shape.bounds.inflate(sensorArea, sensorArea);
        } );

        this.sensors!.push(shapeSensor, boundsSensor, ...sensors);
    }

    update(args: UpdateArgs){

        this.updateHandlers.forEach(h => h(args));

        this.connectorPointA.distance = args.distanceA;
        this.connectorPointB.distance = args.distanceB;

        const path = OrthogonalConnector.route({
            pointA: {...this.connectorPointA, shape: this.connectorPointA.shape.bounds},
            pointB: {...this.connectorPointB, shape: this.connectorPointB.shape.bounds},
            shapeMargin: args.singleMargin,
            globalBoundsMargin: args.comboMargin,
            globalBounds: Rectangle.fromSize(args.canvasSize)
        });

        const {hRulers, vRulers, spots, grid, connections} = OrthogonalConnector.byproduct;

        this.verticalRulers = vRulers;
        this.horizontalRulers = hRulers;
        this.decorations = [...this.extraDecor, ...grid.map(r => new DiagramDecoration(r.deflate(3, 3)))];
        this.hotspots = spots.map(p => new DiagramHotspot(p));
        this.lines = connections.map(ln => new DiagramLine(ln.a, ln.b));

        if(path.length > 0) {
            this.paths = [new DiagramPath(path)];
        }else{
            this.paths = [];
        }


    }
}