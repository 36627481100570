import {DiagramSensor, IDiagram} from "./IDiagram";
import {makePt, Point, Rectangle} from "../helpers/Rectangle";

export interface PointingEvent {
    point: Point;
    touch: boolean;
}

export interface PointingMoveEvent extends PointingEvent{
    gridSnap: number;
}

export interface PointingMoveResult{
    cursor: string | null;
}

interface EditorState {
    dragging? : Rectangle;
    offset? : Point;
    hotSensors?: DiagramSensor[];
}

export class DiagramCanvasController{

    private editorState: EditorState = {};

    constructor(readonly diagram: IDiagram){}

    private sensorsLeave(){
        if(this.editorState.hotSensors) {
            for(const sensor of this.editorState.hotSensors){
                if(sensor.events.mouseLeave) {
                    sensor.events.mouseLeave({point:makePt(0,0)})
                }
            }
        }
        this.editorState.hotSensors = [];
    }

    private sensorsMove(point: Point): string | null{

        const evt = {point};
        let cursorCaught: string | null = null;

        if(!this.diagram.sensors) return null;

        if(!this.editorState.hotSensors) {
            this.editorState.hotSensors = [];
        }

        const inHotSensors = (s: DiagramSensor) => this.editorState.hotSensors!.indexOf(s) >= 0;

        for(const sensor of this.diagram.sensors){

            if(sensor.bounds.contains(point)) {

                if(sensor.events.cursor) {
                    cursorCaught = sensor.events.cursor;
                }

                if(!inHotSensors(sensor)) {
                    // add to hot sensors
                    this.editorState.hotSensors.push(sensor);

                    // Invoke MouseEntered
                    if(sensor.events.mouseEnter){
                        sensor.events.mouseEnter(evt);
                    }
                }
            }

            // Invoke mouseMoves
            if(sensor.events.mouseMove && sensor.bounds.contains(point)) {
                sensor.events.mouseMove(evt)
            }
        }

        if(this.editorState.hotSensors){
            this.editorState.hotSensors = this.editorState.hotSensors.filter(sensor => {
                if(!sensor.bounds.contains(point)) {

                    // Invoke mouseLeave
                    if(sensor.events.mouseLeave){
                        sensor.events.mouseLeave(evt);
                    }
                    // Remove from hot sensors
                    return false;
                }
                return true;
            });
        }

        return cursorCaught;

    }

    pointingGestureStart(e: PointingEvent){
        const point = e.point;

        for(let shape of this.diagram.shapes){
            if(shape.bounds.contains(point)) {
                this.editorState.dragging = shape.bounds;
                this.diagram.draggingShapes = [shape];
                break;
            }
        }

        if(this.editorState.dragging) {
            this.editorState.offset = {x: point.x - this.editorState.dragging.left, y: point.y - this.editorState.dragging.top};
        }

        if(this.diagram.sensors) {
            for(const sensor of this.diagram.sensors){
                if(sensor.events.mouseDown && sensor.bounds.contains(point)) {
                    sensor.events.mouseDown({point})
                }
            }
        }

        this.sensorsMove(point);
    }

    pointingGestureMove(e: PointingMoveEvent): PointingMoveResult{

        const {point, gridSnap} = e;
        const {x,y} = point;

        // Manage
        const cursor = this.sensorsMove(point);

        if(this.diagram && this.diagram.draggingShapes && this.editorState.dragging && this.editorState.offset) {

            const shapeLocation = makePt(x - this.editorState.offset.x, y - this.editorState.offset.y);
            const snapped = gridSnap ?
                makePt(
                    Math.round(shapeLocation.x / gridSnap) * gridSnap,
                    Math.round(shapeLocation.y / gridSnap) * gridSnap) : shapeLocation;

            // Update dragging shape
            this.diagram.draggingShapes[0].bounds = this.editorState.dragging.withLocation(snapped);

        }

        return {cursor};
    }

    pointingGestureEnd(e: PointingEvent){

        this.editorState = {};

        const {point} = e;

        if(this.diagram.sensors) {
            for(const sensor of this.diagram.sensors){
                if(sensor.events.mouseUp) {
                    sensor.events.mouseUp({point})
                }

                if(e.touch && sensor.events.mouseLeave) {
                    sensor.events.mouseLeave({point});
                }
            }
        }

        this.diagram.draggingShapes = [];
    }

}