import {ConnectorDrawingProps, IDiagram, UpdateArgs} from "./IDiagram";
import {BasicCardinalPoint, distance, makePt, Point, Rectangle} from "../helpers/Rectangle";

const COLOR_SHAPE_LIGHT           = `rgb(3, 111, 252)`;
const COLOR_SHAPE_HOT_LIGHT       = `rgb(50, 137, 250)`;
const COLOR_RULER_LIGHT           = `rgba(255, 0, 0, 0.3)`;
const COLOR_DECOR_LIGHT           = `rgba(55, 55, 55, 0.05)`;
const COLOR_LINE_LIGHT            = `rgba(0, 0, 0, 0.2)`;
const COLOR_HOTSPOT_LIGHT         = `rgba(107, 164, 255, 1)`;
const COLOR_GRID_LIGHT            = `rgba(0, 0, 0, 0.1)`;
const COLOR_PATH_LIGHT            = `rgba(0, 0, 0, 1)`;
const COLOR_GIZMO_LIGHT           = `rgba(0, 10, 145, 0.1)`;
const COLOR_GIZMO_HOT_LIGHT       = `rgba(0, 10, 145, 1)`;

const COLOR_SHAPE_DARK           = `rgb(3, 111, 252)`;
const COLOR_SHAPE_HOT_DARK       = `rgb(50, 137, 250)`;
const COLOR_RULER_DARK           = `rgba(255, 0, 0, 0.5)`;
const COLOR_DECOR_DARK           = `rgba(200, 200, 200, 0.05)`;
const COLOR_LINE_DARK            = `rgba(255, 255, 255, 0.2)`;
const COLOR_HOTSPOT_DARK         = `rgba(107, 164, 255, 1)`;
const COLOR_GRID_DARK            = `rgba(255, 255, 255, 0.1)`;
const COLOR_PATH_DARK            = `rgba(255, 255, 255, 1)`;
const COLOR_GIZMO_DARK           = `rgba(255, 245, 110, 0.1)`;
const COLOR_GIZMO_HOT_DARK       = `rgba(255, 245, 110, 1)`;

interface ColorPalette{
    shapeDefault: string;
    shapeHot: string;
    ruler: string;
    decoration: string;
    line: string;
    hotspot: string;
    grid: string;
    path: string;
    gizmoDefault: string;
    gizmoHot: string;
}

interface Corners {
    tl: number;
    tr: number;
    br: number;
    bl: number;
}

export type BendDirection = BasicCardinalPoint | 'unknown' | 'none';

/**
 * Pass three points representing two contiguous line segments
 * If they form a straight horizontal or vertical line, `none` is returned
 * In case they are orthogonal, result indicates the bend direction
 * Any other case the result is `unknown`
 * @param a
 * @param b
 * @param c
 */
export function getBend(a: Point, b: Point, c: Point): BendDirection {

    const equalX = a.x === b.x && b.x === c.x;
    const equalY = a.y === b.y && b.y === c.y;
    const segment1Horizontal = a.y === b.y;
    const segment1Vertical = a.x === b.x;
    const segment2Horizontal = b.y === c.y;
    const segment2Vertical = b.x === c.x;

    if( equalX || equalY ) {
        return 'none';
    }

    if(
        !(segment1Vertical || segment1Horizontal) ||
        !(segment2Vertical || segment2Horizontal)
    ) {
        return 'unknown';
    }

    if(segment1Horizontal && segment2Vertical) {
        return c.y > b.y ? 's' : 'n';

    }else if(segment1Vertical && segment2Horizontal) {
        return c.x > b.x ? 'e' : 'w';
    }

    throw new Error('Nope');

}

/**
 * Removes unnecessary points, where they form part of a straight vertical or horizontal line
 * @param points
 */
export function simplifyPath(points: Point[]): Point[]{

    if(points.length <= 2){
        return points;
    }

    const r: Point[] = [points[0]];
    for(let i = 1; i < points.length; i++){
        const cur = points[i];

        if(i === (points.length - 1)) {
            r.push(cur);
            break;
        }

        const prev = points[i - 1];
        const next = points[i + 1];
        const bend = getBend(prev, cur, next);

        if(bend !== 'none') {
            r.push(cur);
        }

    }
    return r;
}

/**
 * Draws a round path
 * @param points
 * @param c
 * @param radius
 */
export function roundCornersPath(points: Point[], c: CanvasRenderingContext2D, radius: number = 10){

    points = simplifyPath(points);

    if(points.length <= 1) {
        return;
    }

    c.beginPath();

    let prev = points[0];
    c.moveTo(prev.x, prev.y);

    for(let i = 1; i < points.length; i++){
        const current = points[i];
        const {x, y} = current;
        const next: null | Point = points[i + 1] || null;

        if(next) {
            const b = getBend(prev, current, next);
            const d1 = distance(prev, current);
            const d2 = distance(current, next);
            const r2 = radius * 2;
            const r = d1 < r2 || d2 < r2 ? Math.min(d1/2, d2/2) : radius;
            const fromW = prev.x < x;
            const fromN = prev.y < y;

            switch (b) {
                case 's':
                    c.lineTo(fromW ? x - r : x + r, y);
                    c.quadraticCurveTo(x, y, x, y + r);
                    break;
                case 'n':
                    c.lineTo(fromW ? x - r : x + r, y);
                    c.quadraticCurveTo(x, y, x, y - r, );
                    break;
                case 'e':
                    c.lineTo(x, fromN ? y - r : y + r );
                    c.quadraticCurveTo(x, y, x + r, y);
                    break;
                case 'w':
                    c.lineTo(x, fromN ? y - r : y + r );
                    c.quadraticCurveTo(x, y, x - r, y);
                    break;
                default:
                    c.lineTo(x, y);
                    break;
            }
        }else{
            c.lineTo(current.x, current.y);
        }

        prev = current;
    }

    c.stroke();
}

export class DiagramDrawer{

    readonly palette: ColorPalette;

    constructor(
        readonly canvas: HTMLCanvasElement,
        readonly context: CanvasRenderingContext2D,
        readonly diagram: IDiagram,
        readonly props: ConnectorDrawingProps){

        if(window.matchMedia(`(prefers-color-scheme: dark)`).matches) {
            this.palette = {
                shapeDefault:   COLOR_SHAPE_DARK,
                shapeHot:       COLOR_SHAPE_HOT_DARK,
                ruler:          COLOR_RULER_DARK,
                decoration:     COLOR_DECOR_DARK,
                line:           COLOR_LINE_DARK,
                hotspot:        COLOR_HOTSPOT_DARK,
                grid:           COLOR_GRID_DARK,
                path:           COLOR_PATH_DARK,
                gizmoDefault:   COLOR_GIZMO_DARK,
                gizmoHot:       COLOR_GIZMO_HOT_DARK,
            }
        }else{
            this.palette = {
                shapeDefault:   COLOR_SHAPE_LIGHT,
                shapeHot:       COLOR_SHAPE_HOT_LIGHT,
                ruler:          COLOR_RULER_LIGHT,
                decoration:     COLOR_DECOR_LIGHT,
                line:           COLOR_LINE_LIGHT,
                hotspot:        COLOR_HOTSPOT_LIGHT,
                grid:           COLOR_GRID_LIGHT,
                path:           COLOR_PATH_LIGHT,
                gizmoDefault:   COLOR_GIZMO_LIGHT,
                gizmoHot:       COLOR_GIZMO_HOT_LIGHT,
            }
        }
    }

    clear(){
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    private roundRect(bounds: Rectangle, fill: string | null, stroke: string | null = null, radius: number | Corners = 10) {

        const ctx = this.context;
        const {x, y} = bounds.location;
        const {width, height} = bounds.size;

        if (typeof radius === 'number') {
            radius = {tl: radius, tr: radius, br: radius, bl: radius};
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();

        if (fill) {
            ctx.fillStyle = fill;
            ctx.fill();
        }

        if (stroke) {
            ctx.strokeStyle = stroke;
            ctx.stroke();
        }

    }

    private roundPath(points: Point[], stroke: string, radius: number = 10){
        this.context.strokeStyle = stroke;
        roundCornersPath(points, this.context, radius);
    }

    private fillRect(r: Rectangle, style: string){
        this.context.fillStyle = style;
        this.context.fillRect(r.left, r.top, r.width, r.height);
    }

    private line(a: Point, b: Point, style: string, width: number = 1){
        this.context.lineWidth = width;
        this.context.strokeStyle = style;
        this.context.beginPath();
        this.context.moveTo(a.x, a.y);
        this.context.lineTo(b.x, b.y);
        this.context.stroke();
    }

    private fillEllipse(center: Point, radius: number, style: string){
        this.context.fillStyle = style;
        this.context.beginPath();
        this.context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
        this.context.fill();
    }

    private strokeRect(r: Rectangle, style: string, width: number = 1){
        this.context.strokeStyle = style;
        this.context.beginPath();
        this.context.rect(r.left, r.top, r.width, r.height);
        this.context.stroke();
    }

    private drawRulers(){
        // Rulers
        this.context.setLineDash([5, 5]);
        this.context.lineWidth = 1;
        this.context.strokeStyle = this.palette.ruler;

        if(this.diagram.verticalRulers) {
            for(let x of this.diagram.verticalRulers){
                this.context.beginPath();
                this.context.moveTo(x, 0);
                this.context.lineTo(x, this.canvas.height);
                this.context.stroke();
            }
        }
        if(this.diagram.horizontalRulers) {
            for(let y of this.diagram.horizontalRulers){
                this.context.beginPath();
                this.context.moveTo(0, y);
                this.context.lineTo(this.canvas.width, y);
                this.context.stroke();
            }
        }
        this.context.setLineDash([]);
    }

    private drawShapes(){
        for(let shape of this.diagram.shapes){
            const b = shape.bounds;
            const color = shape.hot ? this.palette.shapeHot : this.palette.shapeDefault;
            this.roundRect(b, color);
        }
    }

    private drawDecorations(){
        // Decorations
        if(this.diagram.decorations) {
            for(let decor of this.diagram.decorations){

                this.fillRect(decor.bounds, decor.fillStyle || this.palette.decoration);

                if(decor.strokeStyle) {
                    this.strokeRect(decor.bounds, decor.strokeStyle);
                }
            }
        }
    }

    private drawPaths(){
        for(let path of this.diagram.paths){
            this.roundPath(path.points, this.palette.path);
        }
    }

    private drawLines(){
        if(this.diagram.lines) {
            for(const line of this.diagram.lines){
                this.line(line.a, line.b, this.palette.line);
            }
        }
    }

    private drawHotspots(){
        if(this.diagram.hotspots) {
            for(let h of this.diagram.hotspots){
                const bounds = Rectangle.empty.withLocation(h.point).inflate(3, 3);
                this.fillEllipse(bounds.center, 4, this.palette.hotspot);
            }
        }
    }

    public drawGizmos(){
        if(this.diagram.gizmos) {
            for(let gz of this.diagram.gizmos){
                this.fillEllipse(gz.bounds.center, gz.bounds.width / 2, gz.hot ? this.palette.gizmoHot : this.palette.gizmoDefault);
            }
        }
    }

    public drawGrid(args: UpdateArgs){

        if(args.gridSize == 0) {
            return;
        }

        for(let i = 0; i < Math.max(args.canvasSize.width, args.canvasSize.height); i+= args.gridSize){
            this.line(makePt(i, 0), makePt(i, args.canvasSize.height), this.palette.grid);
            this.line(makePt(0, i), makePt(args.canvasSize.width, i), this.palette.grid);
        }

    }

    draw(args: UpdateArgs){

        this.diagram.update(args);

        this.drawGrid(args);

        if(this.props.drawRulers) this.drawRulers();
        if(this.props.drawShapes) this.drawShapes();
        if(this.props.drawDecorations) this.drawDecorations();
        if(this.props.drawLines) this.drawLines();
        if(this.props.drawPaths) this.drawPaths();
        if(this.props.drawSpots) this.drawHotspots();
        this.drawGizmos();

        this.context.fillStyle = `transparent`;
        this.context.strokeStyle = `transparent`;

    }

    redraw(args: UpdateArgs){
        this.clear();
        this.draw(args);
    }

}