import * as React from "react";
import {RefObject} from "react";
import {makePt, Point, Rectangle} from "../helpers/Rectangle";
import {ConnectorDrawingProps, IDiagram, UpdateArgs} from "../diagram/IDiagram";
import {DiagramDrawer} from "../diagram/DiagramDrawer";
import {DiagramGrid} from "../diagram/DiagramGrid";
import {DiagramCanvasController} from "../diagram/DiagramCanvasController";
import {AppState} from "../index";

export interface DiagramCanvasProps extends AppState{
    // gridSnap?: number;
}

export class DiagramCanvas extends React.Component<DiagramCanvasProps>{

    readonly containerRef: RefObject<HTMLDivElement> = React.createRef();
    readonly canvasRef: RefObject<HTMLCanvasElement> = React.createRef();
    readonly diagram: IDiagram;
    readonly controller: DiagramCanvasController;

    private drawer: DiagramDrawer | null = null;
    private resizer = () => this.updateCanvasSize();
    private updateArgs: UpdateArgs = {
        canvasSize: {width: 0, height: 0},
        singleMargin: 0,
        comboMargin: 0,
        gridSize: 0,
        distanceA: 0,
        distanceB: 0,
    };
    private _cursor: string | null = null;

    constructor(props: DiagramCanvasProps){
        super(props);
        this.diagram = new DiagramGrid();
        this.controller = new DiagramCanvasController(this.diagram);
    }

    private canvasPoint(clientX: number, clientY: number): Point{
        if(this.canvasRef.current) {
            const bounds = Rectangle.fromDOMRect(this.canvasRef.current.getBoundingClientRect());
            return makePt(clientX - bounds.left, clientY - bounds.top);
        }
        return makePt(clientX, clientY);
    }

    private refreshDrawer(){
        if(this.canvasRef.current) {

            const canvas = this.canvasRef.current.getContext('2d');

            if(canvas) {
                this.drawer = new DiagramDrawer(this.canvasRef.current, canvas, this.diagram, this.props);
                this.updateArgs.singleMargin = this.props.singleMargin;
                this.updateArgs.comboMargin = this.props.comboMargin;
                this.updateArgs.gridSize = this.props.gridSnap || 0;
                this.updateArgs.distanceA = this.props.distanceA;
                this.updateArgs.distanceB = this.props.distanceB;
            }
        }
    }

    private startDrawLoop(){
        const draw = () => {
            if(this.drawer) {
                this.drawer.redraw(this.updateArgs);
            }

            requestAnimationFrame(draw);
        };

        requestAnimationFrame(draw);
    }

    componentWillMount(): void {
        this.registerResizeHook();
    }

    componentWillUnmount(): void {
        this.unRegisterResizeHook();
    }

    componentDidMount(): void {
        this.resizer();
        this.refreshDrawer();
        this.startDrawLoop();
    }

    componentDidUpdate(prevProps: Readonly<DiagramCanvasProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.refreshDrawer();
    }

    registerResizeHook(){
        window.addEventListener('resize', this.resizer);
    }

    unRegisterResizeHook(){
        window.removeEventListener('resize', this.resizer);
    }

    updateCanvasSize(){
        if(this.containerRef.current) {

            const rect = Rectangle.fromDOMRect(this.containerRef.current.getBoundingClientRect());

            if(this.canvasRef.current) {
                console.log(`${rect.width} x ${rect.height}`);
                this.canvasRef.current.width = rect.width;
                this.canvasRef.current.height = rect.height;
                this.updateArgs.canvasSize = rect.size;

            }else{
                console.log(`Diagram::resize: No canvasRef`);
            }

        }else{
            console.log(`Diagram::resize: No containerRef`);
        }

    }

    mouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>){

        const point = this.canvasPoint(e.clientX, e.clientY);
        this.controller.pointingGestureStart({point, touch: false});

    }

    mouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>){

        if(this.canvasRef.current) {
            const point = this.canvasPoint(e.clientX, e.clientY);
            this.controller.pointingGestureEnd({point, touch: false});
        }
    }

    mouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>){

        const point = this.canvasPoint(e.clientX, e.clientY);
        const result = this.controller.pointingGestureMove({point, gridSnap: this.props.gridSnap || 0, touch: false});

        this.cursor = result.cursor;

    }

    touchEnd(e: React.TouchEvent<HTMLCanvasElement>){
        if(e.touches.length > 0) {
            const t = e.touches[0];
            this.controller.pointingGestureEnd({
                point: this.canvasPoint(t.clientX, t.clientY),
                touch: true,
            });
        }else{
            this.controller.pointingGestureEnd({point: makePt(0,0), touch: true})
        }
    }

    touchMove(e: React.TouchEvent<HTMLCanvasElement>){
        if(e.touches.length > 0) {
            const t = e.touches[0];
            this.controller.pointingGestureMove({
                point: this.canvasPoint(t.clientX, t.clientY),
                gridSnap: this.props.gridSnap || 0,
                touch: true
            });
        }
    }

    touchStart(e: React.TouchEvent<HTMLCanvasElement>){
        if(e.touches.length > 0) {
            e.preventDefault();
            e.stopPropagation();
            const t = e.touches[0];
            const point = this.canvasPoint(t.clientX, t.clientY);
            this.controller.pointingGestureStart({point, touch: true});
        }

    }

    render(){
        return (
            <div ref={this.containerRef}
                 className={`ui-diagram`}>
                <canvas
                    ref={this.canvasRef}
                    onMouseDown={e => this.mouseDown(e)}
                    onMouseMove={e => this.mouseMove(e)}
                    onMouseUp={e => this.mouseUp(e)}
                    onTouchStart={e => this.touchStart(e)}
                    onTouchMove={e => this.touchMove(e)}
                    onTouchEnd={e => this.touchEnd(e)}
                ></canvas>
            </div>
        );
    }

    get cursor(): string | null{
        return this._cursor;
    }

    set cursor(value: string | null){

        const changed = value !== this._cursor;

        if(changed){

            if(this.canvasRef.current) {
                this.canvasRef.current.style.cursor = value || '';
            }

            this._cursor = value;
        }
    }
}