import './css/styles.css';
import './css/theme.css';
import './css/index.scss';
import * as React from "react";
import * as ReactDOM from "react-dom";
import {ToolbarView} from "./components/hui/layout/ToolbarView";
import {Button} from "./components/hui/items/Button";
import {MenuItem} from "./components/hui/items/MenuItem";
import {DiagramCanvas} from "./components/DiagramCanvas";
import {DockView} from "./components/hui/layout/DockView";
import {ConnectorDrawingProps} from "./diagram/IDiagram";

export interface AppState extends ConnectorDrawingProps{
    gridSnap: number,
    distanceA: number,
    distanceB: number,
}

const initialState: AppState = {
    drawDecorations: false,
    drawRulers: false,
    drawShapes: true,
    drawSpots: false,
    drawPaths: true,
    drawLines: false,
    comboMargin: 50,
    singleMargin: 20,
    gridSnap: 0,
    distanceA: 0.5,
    distanceB: 0.5,
};

interface FlagInputProps {
    text: string;
    prop: keyof AppState;
}

interface SliderInputProps {
    text: string;
    max: number;
    min?: number;
    step?: number;
    value: number;
    setter: (value: number) => void;

}

class RangeInput extends React.Component<SliderInputProps>{
    render(){
        const {text, value, max, setter, min, step} = this.props;
        return (
            <div>
                <div>{text}: {value}</div>
                <input type="range"
                       min={min || 0}
                       max={max}
                       step={step || 1}
                       value={value}
                       onChange={e => setter(parseFloat(e.target.value))}
                />
            </div>
        );
    }
}

function App(){
    const [appState, setAppState] = React.useState(initialState);
    const setOne = (a: { [k in keyof AppState]?: AppState[k]}) => {
        setAppState({...appState, ...a });
        return undefined;
    };

    const FlagInput = ({text, prop}: FlagInputProps) => {
        return (
            <div>
                <label>
                    <input type="checkbox"
                        checked={appState[prop] as boolean}
                           onChange={e => setAppState({...appState, [prop]: e.target.checked})}
                    />
                    <span>{text}</span>
                </label>
            </div>
        );
    };

    const Separator = () => <div className={`separator`} ></div>

    return (
        <ToolbarView
            items={<>
                <Button text={`Orthogonal Connector Routing`}>
                    <MenuItem text={`How to use?`} onActivate={() => alert("[TODO]: Link to Medium Article")}/>
                    <MenuItem text={`About`} onActivate={() => window.open(`https://jose.page`)}/>
                </Button>
            </>}
        >
            <DockView
                side={'right'}
                sideView={(
                    <div className={'controls'}>
                        <h2>How to Use</h2>
                        <ul>
                            <li><a target={`_blank`} href="https://josepage.medium.com/routing-orthogonal-diagram-connectors-in-javascript-191dc2c5ff70">Medium Article</a></li>
                            <li>Drag shapes around</li>
                            <li>Click hot-spots</li>
                            <li>Try options here</li>
                        </ul>
                        <Separator/>
                        <h2>Algorithm Layers</h2>
                        <FlagInput text={`Rulers`} prop={`drawRulers`} />
                        <FlagInput text={`Main Grid`} prop={`drawDecorations`} />
                        <FlagInput text={`Reference Points`} prop={`drawSpots`} />
                        <FlagInput text={`Route Grid`} prop={`drawLines`} />
                        <Separator/>
                        <h2>Diagram Layers</h2>
                        <FlagInput text={`Connectors`} prop={`drawPaths`} />
                        <FlagInput text={`Shapes`} prop={`drawShapes`} />
                        <Separator/>
                        <h2>Adjust</h2>
                        <RangeInput text={`Shape Margin`} setter={v => setOne({singleMargin: v})} value={appState.singleMargin} max={20}/>
                        <RangeInput text={`Routing Margin`} setter={v => setOne({comboMargin: v})} value={appState.comboMargin} min={1} max={100}/>
                        <RangeInput text={`Snap to Grid`} setter={v => setOne({gridSnap: v})} value={appState.gridSnap} max={50} step={25}/>
                        <Separator/>
                        <RangeInput text={`Shape A Position`} setter={v => setOne({distanceA: v})} value={appState.distanceA} max={1} step={0.05}/>
                        <RangeInput text={`Shape B Position`} setter={v => setOne({distanceB: v})} value={appState.distanceB} max={1} step={0.05}/>
                        <Separator/>
                        <div className="about">
                            <p>Connect</p>
                            <ul>
                                <li><a href="https://jose.page" target={`_blank`}>jose.page</a></li>
                                <li><a href="https://github.com/menendezpoo" target={`blank`}>github/menendezpoo</a></li>
                            </ul>
                        </div>
                    </div>
                )}>
                <DiagramCanvas {...appState} />
            </DockView>
        </ToolbarView>
    )
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);